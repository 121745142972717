import React from 'react';

import { EuiGlobalToastList } from '../../vendor/eui/src/components/toast/global_toast_list';

import { TOAST_DURATION_IMPORTANT } from '../../constants';

import { withAppContext } from '../../context';

class GlobalToastsList extends React.Component {
  render() {
    return (
      <EuiGlobalToastList
        side='left'
        dismissToast={this.props.context.removeToast}
        toasts={this.props.context.toasts}
        toastLifeTimeMs={TOAST_DURATION_IMPORTANT}
      />
    );
  }
}

export default withAppContext(GlobalToastsList);
